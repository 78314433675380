<template>
  <div class="widget-content widget-content-area">
    <b-row v-if="currentUser && !isMobileScreenSize" class="mb-1">
      <!-- Project Name -->
      <b-col class="col-5">
        <label
          >{{ $t("soulLetter.project") }} {{ $t("soulLetter.createdAt") }}
          {{
            moment(currentSoulLetter.createTimeStamp).format("DD.MM.YYYY")
          }}</label
        >
        <b-input
          v-model="currentSoulLetter.title"
          type="text"
          class="form-control"
          :debounce="1000"
        />
      </b-col>
    </b-row>
    <b-row v-else-if="currentUser" class="mb-1">
      <b-col class="col-12">
        <label
          >{{ $t("soulLetter.project") }} {{ $t("soulLetter.createdAt") }}
          {{
            moment(currentSoulLetter.createTimeStamp).format("DD.MM.YYYY")
          }}</label
        >
        <b-input
          v-model="currentSoulLetter.title"
          type="text"
          class="form-control"
          :debounce="1000"
        />
      </b-col>
    </b-row>
    <b-form v-if="!isMobileScreenSize">
      <div v-if="loadingMainContent">
        <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
          <div
            class="spinner-border text-success align-self-center loader-lg"
          />
        </div>
      </div>
      <div v-else-if="loadingErrorMainContent" class="cm-loading-error m-4">
        {{ $t("calendarModule.errors.loadingError") }}
      </div>
      <div v-else>
        <b-form-row>
          <b-form-group class="col-md-1" />
          <b-form-group class="col-md-2" />
          <b-form-group class="col-md-6" />
          <b-form-group class="col-md-3"> </b-form-group>
        </b-form-row>

        <b-form-row class="mb-4">
          <b-form-group class="col-md-1">
            <label>{{ $t("soulLetter.reason") }}</label>
            <b-img
              id="tooltip-reason"
              class="feathericons-help-circle p-2 ml-1 bg-success"
            />
            <b-tooltip target="tooltip-reason" triggers="hover">
              <span>{{ $t("soulLetter.toolTips.reasonDescription") }}</span>
            </b-tooltip>
          </b-form-group>
          <b-form-group class="col-md-2">
            <b-input
              type="text"
              class="form-control"
              :placeholder="$t('soulLetter.reason')"
              disabled
              :debounce="1000"
            />
          </b-form-group>
          <b-form-group class="col-md-9">
            <quill-editor
              class="editor"
              v-model="$v.revisionSubjectText.$model"
              ref="revisionSubjectQuillEditor"
              :options="editorOption"
              :disabled="quillDisabled"
              :key="isRerenderList"
              @change="
                inputEvent =>
                  quillDebounce('revisionSubjectText', inputEvent, -1)
              "
              @focus="setFocus($event)"
            />
            <p class="text-center border-soul-letters-characters">
              <!--{{ revisionSubjectTextCharsCount.length }}-->
              {{ revisionSubjectTextTotalLineCount.length }} / {{ maxChars }}
              {{ $t("soulLetter.characters") }}
            </p>
            <span
              class="error"
              v-if="!$v.revisionSubjectTextCharsCount.maxLength"
            >
              {{ $t("soulLetter.error.charactersToMany") }}
            </span>
          </b-form-group>
        </b-form-row>
        <!-- HIER SPALTENÜBERSCHRIFTEN -->
        <b-form-row class="mb-4">
          <b-form-group class="col-md-1">
            <label>{{ $t("soulLetter.row") }}</label>
            <b-img
              id="tooltip-row"
              class="feathericons-help-circle p-2 ml-1 bg-success"
            />
            <b-tooltip target="tooltip-row" triggers="hover">
              <span>{{ $t("soulLetter.toolTips.row") }}</span>
            </b-tooltip>
          </b-form-group>
          <b-form-group class="col-md-2">
            <label>{{ $t("soulLetter.person") }}</label>
            <b-img
              id="tooltip-person"
              class="feathericons-help-circle p-2 ml-1 bg-success"
            />
            <b-tooltip target="tooltip-person" triggers="hover">
              <span>{{ $t("soulLetter.toolTips.person") }}</span>
            </b-tooltip>
          </b-form-group>
          <b-form-group class="col-md-6">
            <label>{{ $t("soulLetter.scene") }}</label>
            <b-img
              id="tooltip-scene"
              class="feathericons-help-circle p-2 ml-1 bg-success"
            />
            <b-tooltip target="tooltip-scene" triggers="hover">
              <span>{{ $t("soulLetter.toolTips.scene") }}</span>
            </b-tooltip>
          </b-form-group>
          <b-form-group class="col-md-3"> </b-form-group>
        </b-form-row>
        <!-- HIER BEGINN DER DYNAMISCHEN ZEILEN -->
        <div v-for="(line, lineIdx) in $v.lineList.$each.$iter" :key="lineIdx">
          <b-form-row class="mb-4 mt-n5">
            <b-form-group class="col-md-1">
              <p :id="'line' + lineIdx">{{ lineIdx }}</p>
              <b-img
                ref="soulLetterDeleteRow"
                class="feathericons-trash p-2 bg-danger btn"
                @click="openDeleteRowModal(lineIdx)"
              />
            </b-form-group>
            <b-form-group class="col-md-2">
              <b-input
                type="text"
                class="form-control"
                v-model="line.actor.$model"
                :debounce="1000"
              />
            </b-form-group>
            <b-form-group class="col-md-9">
              <quill-editor
                class="editor"
                v-model="line.text.$model"
                ref="linesQuillEditor"
                :options="editorOption"
                :disabled="quillDisabled"
                :key="isRerenderList"
                @change="
                  inputEvent => quillDebounce('line', inputEvent, lineIdx)
                "
                @focus="setFocus($event)"
              />
              <p class="text-center border-soul-letters-characters">
                <!--   {{
                  $v.lineListCharsCount.$each[parseInt(lineIdx, 10)].text.$model
                    .length
                }}-->
                <span v-if="lineListTotalCharsCount"
                  >{{
                    lineListTotalCharsCount[parseInt(lineIdx, 10)].text.length
                  }}
                  / {{ maxChars }} {{ $t("soulLetter.characters") }}</span
                >
              </p>
              <span
                class="error"
                v-if="
                  !$v.lineListCharsCount.$each[parseInt(lineIdx, 10)].text
                    .maxLength
                "
              >
                {{ $t("soulLetter.error.charactersToMany") }}
              </span>
            </b-form-group>
            <!--   <b-form-group v-if="currentUser" class="col-md-3">
              <quill-editor
                class="editor quill-comment-section quill-disabled"
                v-model="line.comment.$model"
                ref="linesQuillEditor"
                :options="editorOption"
                :disabled="true"
              />
            </b-form-group>-->
          </b-form-row>
          <b-form-row class="mb-4 mt-n5 ml-auto mr-auto">
            <b-form-group class="col-md-3 mt-4 text-center" />
            <b-form-group class="col-md-9 mt-4 text-center">
              <button
                :id="'lineButton' + lineIdx"
                @click.prevent="rerenderLineList(lineIdx)"
                class="btn btn-outline-success mb-2 text-center"
              >
                {{ $t("soulLetter.addNewLine") }}
              </button>
            </b-form-group>
          </b-form-row>
        </div>
        <b-form-row class="mb-4">
          <b-form-group class="col-md-12 soulLetter-bottom-buttons">
            <div>
              <button
                @click.prevent="openLeaveSoulLetterModal()"
                class="btn btn-danger mb-2 mr-2"
              >
                <b-img class="feathericons-x-square p-2 bg-white" />
                {{ $t("soulLetter.close") }}
              </button>
              <button
                @click.prevent="
                  updateRevision(revisions.length - 1, false, false)
                "
                :disabled="$v.lineListCharsCount.$invalid"
                class="btn btn-warning mb-2 mr-2"
              >
                <b-img class="feathericons-save p-2 bg-white" />
                {{ $t("soulLetter.save") }}
              </button>
              <button
                @click.prevent="openChooseCompanionModal()"
                :disabled="$v.lineListCharsCount.$invalid"
                class="btn btn-success mb-2 mr-2"
              >
                <b-img class="feathericons-send p-2 bg-white" />
                {{ $t("soulLetter.send") }}
              </button>
            </div>
            <div
              class="sl-new-chars-alert col-10 pl-0 mt-2"
              v-if="currentUser && currentSoulLetter"
            >
              <div
                v-if="currentUser._id === currentSoulLetter.sender._id"
                class="alert alert-light-info border-0 mb-4 text-center"
                role="alert"
              >
                {{
                  $t("soulLetter.addedAmountOfLinesInRevision").replace(
                    "%count",
                    calculatedTotalChars
                  )
                }}
              </div>
            </div>
          </b-form-group>
        </b-form-row>
      </div>
    </b-form>
    <b-form v-else>
      <div v-if="loadingMainContent">
        <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
          <div
            class="spinner-border text-success align-self-center loader-lg"
          />
        </div>
      </div>
      <div v-else-if="loadingErrorMainContent" class="cm-loading-error m-4">
        {{ $t("calendarModule.errors.loadingError") }}
      </div>
      <div v-else>
        <b-form-row>
          <b-form-group class="col-md-1" />
          <b-form-group class="col-md-2" />
          <b-form-group class="col-md-6" />
        </b-form-row>
        <b-form-row class="mb-4">
          <b-form-group class="col-md-1">
            <label>{{ $t("soulLetter.reason") }}</label>
            <b-img
              id="tooltip-reason-mobile"
              class="feathericons-help-circle p-2 ml-1 bg-success"
            />
            <b-tooltip target="tooltip-reason" triggers="hover">
              <span>{{ $t("soulLetter.toolTips.reasonDescription") }}</span>
            </b-tooltip>
            <b-tooltip target="tooltip-reason-mobile" triggers="hover">
              <span>{{ $t("soulLetter.toolTips.reasonDescription") }}</span>
            </b-tooltip>
          </b-form-group>
          <b-form-group class="col-md-2">
            <b-input
              type="text"
              class="form-control"
              :placeholder="$t('soulLetter.reason')"
              disabled
              :debounce="1000"
            />
          </b-form-group>
          <b-form-group class="col-md-6">
            <quill-editor
              class="editor"
              v-model="$v.revisionSubjectText.$model"
              ref="revisionSubjectQuillEditor"
              :options="editorOption"
              :disabled="quillDisabled"
              :key="isRerenderList"
              @change="
                inputEvent =>
                  quillDebounce('revisionSubjectText', inputEvent, -1)
              "
              @focus="setFocus($event)"
            />
            <p class="text-center border-soul-letters-characters">
              <!--{{ revisionSubjectTextCharsCount.length }}-->
              {{ revisionSubjectTextTotalLineCount.length }} / {{ maxChars }}
              {{ $t("soulLetter.characters") }}
            </p>
            <span
              class="error"
              v-if="!$v.revisionSubjectTextCharsCount.maxLength"
            >
              {{ $t("soulLetter.error.charactersToMany") }}
            </span>
          </b-form-group>
          <b-form-group class="col-md-3">
            <label>{{ $t("soulLetter.comment") }}</label>
          </b-form-group>
        </b-form-row>
        <!-- HIER SPALTENÜBERSCHRIFTEN -->
        <b-form-row class="mb-4">
          <b-form-group class="col-md-3"> </b-form-group>
        </b-form-row>
        <!-- HIER BEGINN DER DYNAMISCHEN ZEILEN -->
        <div v-for="(line, lineIdx) in $v.lineList.$each.$iter" :key="lineIdx">
          <b-form-row class="mt-1">
            <b-form-group class="col-12 mb-0">
              <label>{{ $t("soulLetter.row") }}</label>
              <b-img
                id="tooltip-row-mobile"
                class="feathericons-help-circle p-2 ml-1 bg-success"
              />
              <b-tooltip target="tooltip-row" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.row") }}</span>
              </b-tooltip>
              <b-tooltip target="tooltip-row-mobile" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.row") }}</span>
              </b-tooltip>
            </b-form-group>
            <b-form-group class="col-12">
              <span class="mr-3" :id="'line' + lineIdx">{{ lineIdx }}</span>
              <b-img
                ref="soulLetterDeleteRow"
                class="feathericons-trash p-2 bg-danger btn"
                @click="openDeleteRowModal(lineIdx)"
              />
            </b-form-group>
          </b-form-row>
          <b-form-row class="mt-1">
            <b-form-group class="col-md-12 mb-0">
              <label>{{ $t("soulLetter.person") }}</label>
              <b-img
                id="tooltip-person-mobile"
                class="feathericons-help-circle p-2 ml-1 bg-success"
              />
              <b-tooltip target="tooltip-person" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.person") }}</span>
              </b-tooltip>
              <b-tooltip target="tooltip-person-mobile" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.person") }}</span>
              </b-tooltip>
            </b-form-group>
            <b-form-group class="col-md-12">
              <b-input
                type="text"
                class="form-control"
                v-model="line.actor.$model"
                :debounce="1000"
              />
            </b-form-group>
          </b-form-row>
          <b-form-row class="mt-1">
            <b-form-group class="col-md-12 mb-0">
              <label>{{ $t("soulLetter.scene") }}</label>
              <b-img
                id="tooltip-scene-mobile"
                class="feathericons-help-circle p-2 ml-1 bg-success"
              />
              <b-tooltip target="tooltip-scene" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.scene") }}</span>
              </b-tooltip>
              <b-tooltip target="tooltip-scene-mobile" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.scene") }}</span>
              </b-tooltip>
            </b-form-group>
            <b-form-group class="col-md-12">
              <quill-editor
                class="editor"
                v-model="line.text.$model"
                ref="linesQuillEditor"
                :options="editorOption"
                :disabled="quillDisabled"
                :key="isRerenderList"
                @change="
                  inputEvent => quillDebounce('line', inputEvent, lineIdx)
                "
                @focus="setFocus($event)"
              />
              <p class="text-center border-soul-letters-characters">
                <!--   {{
                  $v.lineListCharsCount.$each[parseInt(lineIdx, 10)].text.$model
                    .length
                }}-->
                <span v-if="lineListTotalCharsCount"
                  >{{
                    lineListTotalCharsCount[parseInt(lineIdx, 10)].text.length
                  }}
                  / {{ maxChars }} {{ $t("soulLetter.characters") }}</span
                >
              </p>
              <span
                class="error"
                v-if="
                  !$v.lineListCharsCount.$each[parseInt(lineIdx, 10)].text
                    .maxLength
                "
              >
                {{ $t("soulLetter.error.charactersToMany") }}
              </span>
            </b-form-group>
          </b-form-row>
          <b-form-row class="mb-4 ml-auto mr-auto">
            <b-form-group class="col-md-12 mt-4 text-center">
              <button
                :id="'lineButton' + lineIdx"
                @click.prevent="rerenderLineList(lineIdx)"
                class="btn btn-outline-success mb-2 text-center"
              >
                {{ $t("soulLetter.addNewLine") }}
              </button>
            </b-form-group>
          </b-form-row>
        </div>
        <b-form-row class="mb-4">
          <b-form-group class="col-md-12 soulLetter-bottom-buttons">
            <div>
              <button
                @click.prevent="openLeaveSoulLetterModal()"
                class="btn btn-danger mb-2 mr-2"
              >
                <b-img class="feathericons-x-square p-2 bg-white" />
                {{ $t("soulLetter.close") }}
              </button>
              <button
                @click.prevent="
                  updateRevision(revisions.length - 1, false, false)
                "
                :disabled="$v.lineListCharsCount.$invalid"
                class="btn btn-warning mb-2 mr-2"
              >
                <b-img class="feathericons-save p-2 bg-white" />
                {{ $t("soulLetter.save") }}
              </button>
              <button
                @click.prevent="openChooseCompanionModal()"
                :disabled="$v.lineListCharsCount.$invalid"
                class="btn btn-success mb-2 mr-2"
              >
                <b-img class="feathericons-send p-2 bg-white" />
                {{ $t("soulLetter.send") }}
              </button>
            </div>
            <div
              class="sl-new-chars-alert col-10 pl-0 mt-2"
              v-if="currentUser && currentSoulLetter"
            >
              <div
                v-if="currentUser._id === currentSoulLetter.sender._id"
                class="alert alert-light-info border-0 mb-4 text-center"
                role="alert"
              >
                {{
                  $t("soulLetter.addedAmountOfLinesInRevision").replace(
                    "%count",
                    calculatedTotalChars
                  )
                }}
              </div>
            </div>
          </b-form-group>
        </b-form-row>
      </div>
    </b-form>
    <!-- Delete Row Modal -->
    <b-modal
      :title="$t('soulLetter.deleteRow')"
      ref="deleteRowModal"
      hide-footer
      onclose="closeDeleteRowModal()"
    >
      <p>{{ $t("soulLetter.confirmDeleteRow") }}</p>
      <button
        @click="removeElementFromLineList"
        class="btn btn-success mb-2 mr-2"
      >
        {{ $t("soulLetter.deleteRow") }}
      </button>
      <button
        @click="closeDeleteRowModal"
        class="btn btn-outline-danger mb-2 mr-2"
      >
        {{ $t("soulLetter.abort") }}
      </button>
    </b-modal>
    <!-- Send SoulLetter Modal -->
    <b-modal
      :title="$t('soulLetter.sendSoulLetter')"
      ref="sendSoulLetterModal"
      size="lg"
      hide-footer
      onclose="closeSendSoulLetterModal()"
    >
      <div v-if="currentSoulLetter && currentUser" class="mb-5">
        <div v-if="currentUser._id === currentSoulLetter.sender._id">
          <b-row class="mb-3">
            <b-col>
              <p>{{ $t("soulLetter.sendSoulLetterDescription") }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-5">
              <h6 class="font-weight-bold">
                {{ $t("soulLetter.countedCharacterAmount") }} :
              </h6>
            </b-col>
            <b-col>
              {{ totalCharacters }}
              <!--{{ currentSoulLetter.latestRevision.billableCharacterCount }}-->
            </b-col>
          </b-row>
          <b-row v-if="revisions.length > 0">
            <b-col class="col-5">
              <h6 class="font-weight-bold">
                {{ $t("soulLetter.estimatedPrice") }} :
              </h6>
            </b-col>
            <div
              v-if="
                revisions[revisions.length - 1].hasOwnProperty('predictedPrice')
              "
            >
              <b-col>
                <span>{{
                  revisions[revisions.length - 1].predictedPrice.price
                }}</span>
                <span>{{
                  revisions[revisions.length - 1].predictedPrice.currency
                }}</span>
              </b-col>
            </div>
          </b-row>
        </div>
        <div v-else>
          <h6 class="font-weight-bold">
            {{ $t("soulLetter.sendSoulLetterConfirmation") }}
          </h6>
        </div>
      </div>
      <button
        @click.prevent="submitSoulLetter()"
        :disabled="$v.lineListCharsCount.$invalid"
        class="btn btn-success mb-2 mr-2"
      >
        <b-img class="feathericons-send p-2 bg-white" />
        {{ $t("soulLetter.send") }}
      </button>
      <button
        @click="closeSendSoulLetterModal"
        class="btn btn-outline-danger mb-2 mr-2"
      >
        {{ $t("soulLetter.abort") }}
      </button>
    </b-modal>
    <!-- Choose Companion Modal -->
    <b-modal
      :title="$t('soulLetter.chooseCompanion')"
      ref="chooseCompanionModal"
      size="xl"
      hide-footer
      onclose="closeChooseCompanionModal()"
    >
      <div v-if="currentSoulLetter" class="mb-5">
        <b-row class="mb-5">
          <b-col>
            <h5 class="font-weight-bold text-center">
              {{ $t("soulLetter.sendSoulLetterDescWithoutComp") }}
            </h5>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="col-12">
            <CompanionSelection
              :set-chosen-companion="val => setChosenCompanion(val)"
              :is-from-soul-letter-without-comp="true"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <!-- Leave SoulLetter Modal -->
    <b-modal
      :title="$t('soulLetter.leaveSoulLetterModal.title')"
      ref="leaveSoulLetterModal"
      size="md"
      hide-footer
      onclose="closeLeaveSoulLetterModal()"
    >
      <b-row class="mb-5">
        <b-col>
          <h6>{{ $t("soulLetter.leaveSoulLetterModal.description") }}</h6>
        </b-col>
      </b-row>
      <button
        @click.prevent="closeSoulLetter()"
        class="btn btn-success mb-2 mr-2"
      >
        {{ $t("misc.confirm") }}
      </button>
      <button
        @click="closeLeaveSoulLetterModal()"
        class="btn btn-outline-danger mb-2 mr-2"
      >
        {{ $t("soulLetter.abort") }}
      </button>
    </b-modal>
    <notifications classes="top-right-notification" />
  </div>
</template>

<script>
import * as ESoulLetterStatus from "@/store/modules/soulLetters/ESoulLetterStatus";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as revisionsApi from "@/store/modules/soulLetters/revisions/revisions.api";
import * as soulLettersApi from "@/store/modules/soulLetters/soulLetters.api";
import { addNotification } from "@/utils/notificationHandler";
import { quillEditor } from "vue-quill-editor";
import undoIcon from "quill/assets/icons/undo.svg";
import redoIcon from "quill/assets/icons/redo.svg";
import { Quill } from "vue-quill-editor/dist/ssr";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { convertToLocal } from "@/utils/timezoneHelper";
import { diffChars, diffWords } from "diff";
import { maxLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as Diff from "text-diff";
import _ from "lodash";
import screenSizeMixin from "@/mixins/screenSize";

export default {
  name: "SoulLetterWithoutRecipient",
  title: "SoulLetters",
  components: {
    quillEditor,
    CompanionSelection: () =>
      import("@/components/soulLetters/CompanionSelection")
  },
  mixins: [screenSizeMixin],
  props: {
    soulLetterTitle: String,
    currentSoulLetter: Object
  },
  data() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return {
      loadingMainContent: false,
      loadingErrorMainContent: false,
      debounceTimeout: null,
      debounceTimeouts: {},
      ignoreNextDebounce: false,
      lineListDifferencesHtml: [],
      subjectDifferencesHtml: [],
      EUserRoles,
      ESoulLetterStatus,
      moment,
      tmpQuillEditor: null,
      calculatedTotalChars: 0,
      isRerenderList: false,
      quillDisabled: false,
      totalCharacters: 0,
      loading: false,
      loadingError: false,
      currentUser: null,
      currentRevision: null,
      chosenClient: null,
      chosenCompanion: null,
      isCompanionChosen: false,
      revisions: [],
      revisionSubjectText: "",
      revisionSubjectTextCharsCount: "",
      revisionSubjectTextTotalLineCount: "",
      timerForRevisionUpdateIntervalCron: null,
      lineList: [],
      lineListCharsCount: [],
      lineListTotalCharsCount: [],
      globalLineIdx: null,
      charactersCountReason: 0,
      soulLetterComment: "",
      maxChars: 1000,
      editorOption: {
        placeholder: "",
        theme: "snow",
        modules: {
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true
          },
          toolbar: {
            container: [
              ["bold", "strike"],
              [{ color: [] }, { background: [] }],
              ["clean"],
              [{ undo: undoIcon }, { redo: redoIcon }]
            ],
            handlers: {
              redo() {
                self.redoQuillChange();
              },
              undo() {
                self.undoQuillChange();
              }
            }
          }
        }
      }
    };
  },
  validations: {
    lineList: {
      $each: {
        actor: {
          maxLength: maxLength(100)
        },
        text: {
          maxLength: maxLength(1000)
        },
        comment: {
          maxLength: maxLength(1000)
        }
      }
    },
    lineListCharsCount: {
      $each: {
        text: {
          maxLength: maxLength(1000)
        }
      }
    },
    revisionSubjectText: {
      maxLength: maxLength(1000)
    },
    revisionSubjectTextCharsCount: {
      maxLength: maxLength(1000)
    }
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"]),
    editor() {
      return this.tmpQuillEditor;
    }
    /*calculatedTotalChars() {
      let totalCharacters = 0;

      totalCharacters =
        totalCharacters + this.revisionSubjectTextCharsCount.length; //revisionSubjectTextWithoutExtraChars.length;
      this.lineListCharsCount.forEach(line => {
        const lineWithoutExtraChars = this.removeHTMLFromString(line.text);
        totalCharacters = totalCharacters + lineWithoutExtraChars.length;
      });
      return totalCharacters;
    }*/
  },
  methods: {
    quillDebounce(variable, inputEvent, lineIdx = null) {
      if (this.ignoreNextDebounce) {
        this.ignoreNextDebounce = false;
        return;
      }

      const debounceTimeoutIndex = lineIdx ? `${variable}${lineIdx}` : variable;
      if (this.debounceTimeouts[debounceTimeoutIndex] !== null) {
        clearTimeout(this.debounceTimeouts[debounceTimeoutIndex]);
      }

      this.debounceTimeouts[debounceTimeoutIndex] = setTimeout(() => {
        if (variable === "line") {
          this.lineList[lineIdx].text = inputEvent.html;
        } else if (variable === "lineComment") {
          this.lineList[lineIdx].comment = inputEvent.html;
        } else if (variable === "soulLetterComment") {
          this.soulLetterComment = inputEvent.html;
        } else if (variable === "revisionSubjectText") {
          this.$v.revisionSubjectText.$model = inputEvent.html;
        } else if (variable === "revisionSubjectComment") {
          this.revisions[this.revisions.length - 1].subject.comment =
            inputEvent.html;
        }
        if (lineIdx !== null) {
          if (lineIdx === -1) {
            this.countCharacters();
          } else {
            this.countCharactersForLines(lineIdx);
          }
          this.limitChars(inputEvent, lineIdx);
        }
      }, 1000);
    },
    undoQuillChange() {
      if (this.editor) {
        this.editor.history.undo();
      }
    },
    redoQuillChange() {
      if (this.editor) {
        this.editor.history.redo();
      }
    },
    setCalculatedTotalChars() {
      this.calculatedTotalChars = 0;

      this.calculatedTotalChars =
        this.calculatedTotalChars + this.revisionSubjectTextCharsCount.length; //revisionSubjectTextWithoutExtraChars.length;
      this.lineListCharsCount.forEach(line => {
        const lineWithoutExtraChars = this.removeHTMLFromString(line.text);
        this.calculatedTotalChars =
          this.calculatedTotalChars + lineWithoutExtraChars.length;
      });
    },
    setChosenCompanion(val) {
      this.chosenCompanion = val;
      this.isCompanionChosen = true;
      this.updateSoulLetter();
    },
    closeSoulLetter() {
      this.updateRevision(this.revisions.length - 1, true, false);
    },
    getPreviousRevision() {
      for (let i = 1; i < this.revisions.length; i++) {
        if (this.revisions[i]._id === this.currentRevision._id)
          return this.revisions[i - 1];
      }
      return null;
    },
    getDiffPatches(original, followup) {
      const diff = new Diff();
      return diff.main(
        original.replaceAll(/(<([^>]+)>)/gi, ""),
        followup.replaceAll(/(<([^>]+)>)/gi, "")
      );
    },
    /*  countCharactersForLines(lIdx) {
      const line = this.lineList[lIdx];
      const previousRevision = this.getPreviousRevision();

      let previousLine = null;
      if (previousRevision) {
        previousLine = previousRevision.lines.find(
          prevLine => line._id === prevLine._id
        );
      }

      if (previousRevision && previousLine) {
        const patches = this.getDiffPatches(previousLine.text, line.text);
        let result = "";
        for (const patch of patches) {
          if (patch[0] !== 1) continue;
          result += patch[1]
            .replaceAll(/(<([^>]+)>)/gi, "")
            .replaceAll(" ", "");
        }
        this.lineListCharsCount[lIdx].text = result;
      } else {
        this.lineListCharsCount[lIdx].text = line.text
          .replaceAll(/(<([^>]+)>)/gi, "")
          .replaceAll(" ", "");
      }
      this.lineListTotalCharsCount[lIdx].text = JSON.parse(
        JSON.stringify(
          line.text.replaceAll(/(<([^>]+)>)/gi, "").replaceAll(" ", "")
        )
      );
    },
    countCharacters() {
      const previousRevision = this.getPreviousRevision();
      if (previousRevision) {
        const patches = this.getDiffPatches(
          previousRevision.subject.text,
          this.revisionSubjectText
        );
        // let count = 0;
        let result = "";
        for (const patch of patches) {
          if (patch[0] !== 1) continue;
          result += patch[1]
            .replaceAll(/(<([^>]+)>)/gi, "")
            .replaceAll(" ", "");
        }
        //    this.charactersCountReason = count;
        this.revisionSubjectTextCharsCount = result;
      } else {
        this.revisionSubjectTextCharsCount = this.revisionSubjectText
          .replaceAll(/(<([^>]+)>)/gi, "")
          .replaceAll(" ", "");
      }
      this.revisionSubjectTextTotalLineCount = this.revisionSubjectText
        .replaceAll(/(<([^>]+)>)/gi, "")
        .replaceAll(" ", "");
    },*/
    removeHTMLFromString(str, cullSpaces = false) {
      return _.unescape(
        str
          .replaceAll(/(<([^>]+)>)/gi, "")
          .replaceAll(" ", "")
          .replaceAll("&nbsp;", "")
      );
      /* const safeString = str.replace(/(<([^>]+)>)/gi, "");
      const withOutWhiteSpaceStr = safeString.replace(/\s/g, "");
      return withOutWhiteSpaceStr;*/
    },
    countCharactersForLines(lIdx) {
      const line = this.lineList[lIdx];
      const previousRevision = this.getPreviousRevision();

      let previousLine = null;
      if (previousRevision) {
        previousLine = previousRevision.lines.find(
          prevLine => line._id === prevLine._id
        );
      }

      if (previousRevision && previousLine) {
        const patches = this.getDiffPatches(previousLine.text, line.text);
        let result = "";
        for (const patch of patches) {
          if (patch[0] !== 1) continue;
          result += this.htmlToTextWithoutWs(patch[1]);
        }
        this.lineListCharsCount[lIdx].text = result;
      } else {
        this.lineListCharsCount[lIdx].text = this.htmlToTextWithoutWs(
          line.text
        );
      }
      this.lineListTotalCharsCount[lIdx].text = this.htmlToTextWithoutWs(
        JSON.parse(JSON.stringify(line.text))
      );
    },
    htmlToTextWithoutWs(str) {
      return _.unescape(
        str
          .replaceAll(/(<([^>]+)>)/gi, "")
          .replaceAll(" ", "")
          .replaceAll("&nbsp;", "")
      );
    },
    countCharacters() {
      const previousRevision = this.getPreviousRevision();
      if (previousRevision) {
        const patches = this.getDiffPatches(
          previousRevision.subject.text,
          this.revisionSubjectText
        );
        let result = "";
        for (const patch of patches) {
          if (patch[0] !== 1) continue;
          result += this.htmlToTextWithoutWs(patch[1]);
        }
        this.revisionSubjectTextCharsCount = result;
      } else {
        this.revisionSubjectTextCharsCount = this.htmlToTextWithoutWs(
          this.revisionSubjectText
        );
      }

      this.revisionSubjectTextTotalLineCount = this.htmlToTextWithoutWs(
        this.revisionSubjectText
      );
    },
    limitChars({ quill, html, text }, lineIndex) {
      const lIdx = parseInt(lineIndex, 10);
      let doCalculation = false;
      if (lIdx > -1 && this.lineListTotalCharsCount[lIdx]) {
        if (this.lineListTotalCharsCount[lIdx].text.length > 1000) {
          doCalculation = true;
        }
      } else if (lIdx === -1) {
        if (this.revisionSubjectTextTotalLineCount.length > 1000) {
          doCalculation = true;
        }
      }

      if (doCalculation) {
        let charCounter = 0;
        let positionOfChar1000 = null;
        for (let i = 0; i < text.length; i++) {
          if (
            text.charAt(i) !== " " &&
            text.charAt(i).charCodeAt(0) !== 160 &&
            text.charAt(i) !== "\n" &&
            text.charAt(i) !== "\r"
          ) {
            const c = text.charAt(i);
            charCounter++;
            if (charCounter === 1000) {
              positionOfChar1000 = i;
              break;
            }
          }
        }
        if (positionOfChar1000) {
          quill.deleteText(positionOfChar1000 + 1, quill.getLength());
          this.ignoreNextDebounce = true;
          alert(this.$t("soulLetter.error.charactersToMany"));
        }
      }
    },
    /* limitChars({ quill, html, text }, lineIndex) {
      const lIdx = parseInt(lineIndex, 10);

      this.tmpQuillEditor = quill;
      if (lIdx > -1) {
        this.lineListTotalCharsCount[lIdx].text = JSON.parse(
          JSON.stringify(
            this.lineList[lIdx].text
              .replaceAll(/(<([^>]+)>)/gi, "")
              .replaceAll(" ", "")
          )
        );
        const ws =
          quill.getLength() - this.lineListTotalCharsCount[lIdx].text.length;

        if (this.lineListTotalCharsCount[lIdx].text.length > 1000) {
          quill.deleteText(
            999 + ws,
            this.lineListTotalCharsCount[lIdx].text.length + ws
          );
          window.alert(this.$t("soulLetter.error.charactersToMany"));
        }
      } else if (lIdx === -1) {
        const wsSubjectText =
          quill.getLength() - this.revisionSubjectTextTotalLineCount.length;
        //  if (quill.getLength() > 1000) {
        if (this.revisionSubjectTextTotalLineCount.length > 1000) {
          quill.deleteText(
            999 + wsSubjectText,
            this.revisionSubjectTextTotalLineCount.length + wsSubjectText
          );
          window.alert(this.$t("soulLetter.error.charactersToMany"));
        }
      }
    },*/
    removeElementFromLineList() {
      this.lineList.splice(this.globalLineIdx, 1);
      this.lineListCharsCount.splice(this.globalLineIdx, 1);
      this.lineListTotalCharsCount.splice(this.globalLineIdx, 1);
      this.$refs["deleteRowModal"].hide();
    },
    setFocus(event) {
      this.tmpQuillEditor = event;
      this.$nextTick(() => {
        this.quillDisabled = false;
      });
      setTimeout(() => {
        event.focus();
      });
    },
    rerenderLineList(lineIdx) {
      this.$nextTick(() => {
        this.quillDisabled = true;
      });
      this.isRerenderList = !this.isRerenderList;
      this.addElementToLineList(lineIdx);
    },
    addElementToLineList(lineIdx) {
      this.$nextTick(() => {
        this.quillDisabled = true;
      });
      const lineIdxNumber = parseInt(lineIdx, 10);
      if (lineIdxNumber === this.lineList.length - 1) {
        const payload = {
          _id: uuidv4(),
          actor: "",
          comment: "",
          text: ""
        };
        const payloadCharsCount = JSON.parse(JSON.stringify(payload));
        const payloadTotalCharsCount = JSON.parse(JSON.stringify(payload));
        this.lineList.push(payload);
        const length = this.lineListCharsCount.push(payloadCharsCount);
        this.lineListTotalCharsCount.push(payloadTotalCharsCount);
        this.countCharactersForLines(length - 1);
      } else {
        const payload = {
          _id: uuidv4(),
          actor: "",
          comment: "",
          text: ""
        };
        const payloadCharsCount = JSON.parse(JSON.stringify(payload));
        const payloadTotalCharsCount = JSON.parse(JSON.stringify(payload));
        this.lineList.splice(lineIdxNumber + 1, 0, payload);
        this.lineListCharsCount.splice(lineIdxNumber + 1, 0, payloadCharsCount);
        this.lineListTotalCharsCount.splice(
          lineIdxNumber + 1,
          0,
          payloadTotalCharsCount
        );
        this.countCharactersForLines(lineIdxNumber + 1);
      }
    },
    setRevisionVariables() {
      this.revisionSubjectText = this.revisions[
        this.revisions.length - 1
      ].subject.text;
    },
    updateLineList(currentRevision) {
      this.lineList = [];
      this.lineListCharsCount = [];
      this.lineListTotalCharsCount = [];
      this.revisionSubjectText = currentRevision.subject.text;
      for (let j = 0; j < currentRevision.lines.length; j++) {
        const line = {
          _id: currentRevision.lines[j]._id,
          actor: currentRevision.lines[j].actor,
          text: currentRevision.lines[j].text,
          comment: currentRevision.lines[j].comment
        };
        const lineForCharactersCount = JSON.parse(JSON.stringify(line));
        lineForCharactersCount.text = this.removeHTMLFromString(
          lineForCharactersCount.text
        );
        const lineForTotalCharactersCount = JSON.parse(JSON.stringify(line));
        lineForTotalCharactersCount.text = this.removeHTMLFromString(
          lineForTotalCharactersCount.text
        );
        this.lineList.push(line);
        const length = this.lineListCharsCount.push(lineForCharactersCount);
        this.lineListTotalCharsCount.push(lineForTotalCharactersCount);
        this.countCharactersForLines(length - 1);
      }
      if (this.lineList.length < 1) {
        this.addElementToLineList(0);
      }
    },
    constructLineList(isFirstMount) {
      this.lineList = [];
      this.lineListCharsCount = [];
      this.lineListTotalCharsCount = [];
      const latestRevisionCount = this.revisions.length - 1;
      for (
        let j = 0;
        j < this.revisions[latestRevisionCount].lines.length;
        j++
      ) {
        const line = {
          _id: this.revisions[latestRevisionCount].lines[j]._id,
          actor: this.revisions[latestRevisionCount].lines[j].actor,
          text: this.revisions[latestRevisionCount].lines[j].text,
          comment: this.revisions[latestRevisionCount].lines[j].comment
        };
        const lineForCharactersCount = JSON.parse(JSON.stringify(line));
        lineForCharactersCount.text = this.removeHTMLFromString(
          lineForCharactersCount.text
        );
        const lineForTotalCharactersCount = JSON.parse(JSON.stringify(line));
        lineForTotalCharactersCount.text = this.removeHTMLFromString(
          lineForTotalCharactersCount.text
        );
        this.lineList.push(line);
        const length = this.lineListCharsCount.push(lineForCharactersCount);
        this.lineListTotalCharsCount.push(lineForTotalCharactersCount);
        this.countCharactersForLines(length - 1);
        /* if (isFirstMount) {
          this.lineListDifferencesHtml.push(this.getLineDifferences(j));
          this.subjectDifferencesHtml.push(this.getSummaryDifferences(j));
        }*/
      }
      if (this.lineList.length < 1) {
        this.addElementToLineList(0);
      }
    },
    /* removeHTMLFromString(str, cullSpaces = false) {
      const safeString = str.replace(/(<([^>]+)>)/gi, "");
      const withOutWhiteSpaceStr = safeString.replace(/\s/g, "");
      return withOutWhiteSpaceStr;
      // return cullSpaces ? safeString.replace(" ", "") : safeString;
    },*/
    openDeleteRowModal(lineIdx) {
      this.globalLineIdx = lineIdx;
      this.$refs["deleteRowModal"].show();
    },
    closeDeleteRowModal() {
      this.globalLineIdx = null;
      this.$refs["deleteRowModal"].hide();
    },
    closeSendSoulLetterModal() {
      this.$refs["sendSoulLetterModal"].hide();
    },
    openSendSoulLetterModal() {
      this.totalCharacters = 0;
      this.totalCharacters =
        this.totalCharacters + this.revisionSubjectTextCharsCount.length; //revisionSubjectTextWithoutExtraChars.length;

      // this.totalCharacters = this.totalCharacters + this.charactersCountReason; //revisionSubjectTextWithoutExtraChars.length;
      this.lineListCharsCount.forEach(line => {
        const lineWithoutExtraChars = this.removeHTMLFromString(line.text);
        this.totalCharacters =
          this.totalCharacters + lineWithoutExtraChars.length;
      });
      this.updateRevision(this.revisions.length - 1, false, false, true);
      this.$refs["sendSoulLetterModal"].show();
    },
    closeChooseCompanionModal() {
      this.$refs["chooseCompanionModal"].hide();
    },
    openChooseCompanionModal() {
      this.$refs["chooseCompanionModal"].show();
    },
    closeLeaveSoulLetterModal() {
      this.$refs["leaveSoulLetterModal"].hide();
    },
    openLeaveSoulLetterModal() {
      this.$refs["leaveSoulLetterModal"].show();
    },
    updateRevision(
      revisionIdxString,
      isCloseSoulLetter,
      isAutomaticUpdate,
      isOpenSoulLetterModal
    ) {
      this.setCalculatedTotalChars();
      const revisionIdx = parseInt(revisionIdxString, 10);
      this.loading = true;
      this.loadingError = false;
      const tmpLines = [];
      this.lineList.forEach(line => {
        tmpLines.push(line);
      });

      revisionsApi
        .updateRevision(
          this.currentSoulLetter._id,
          this.revisions[revisionIdx]._id,
          {
            subject: {
              text: this.revisionSubjectText,
              comment: this.revisions[this.revisions.length - 1].subject.comment
            },
            lines: this.lineList
          }
        )
        .then(res => {
          if (isOpenSoulLetterModal) {
            this.getAllRevisions(isOpenSoulLetterModal);
          }
          if (!isAutomaticUpdate) {
            this.loading = false;
            this.loadingError = false;
            addNotification(
              "success",
              this.$t("misc.success"),
              this.$t("soulLetter.success")
            );
          }
          if (isCloseSoulLetter) {
            const payload = {
              // recipient: this.currentSoulLetter.recipient._id,
              title: this.currentSoulLetter.title,
              comment: this.soulLetterComment
            };
            soulLettersApi
              .updateSoulLetter(this.currentSoulLetter._id, payload)
              .then(res => {
                this.loading = false;
                this.loadingError = false;
                // this.getAllRevisions();
                if (!isAutomaticUpdate) {
                  addNotification(
                    "success",
                    this.$t("misc.success"),
                    this.$t("soulLetter.success")
                  );
                }
                if (isCloseSoulLetter) {
                  clearInterval(this.timerForRevisionUpdateIntervalCron);
                  this.$refs["leaveSoulLetterModal"].hide();
                  this.$emit("soulLetterSavedAndSent");
                }
              })
              .catch(err => {
                this.loadingError = true;
                this.loading = false;
                if (!isAutomaticUpdate) {
                  addNotification(
                    "error",
                    this.$t("misc.error"),
                    this.$t("soulLetter.error.somethingWentWrong")
                  );
                }
              });
          }
        })
        .catch(err => {
          this.loadingError = true;
          this.loading = false;
          if (!isAutomaticUpdate) {
            addNotification(
              "error",
              this.$t("misc.error"),
              this.$t("soulLetter.error.somethingWentWrong")
            );
          }
        });
    },
    updateSoulLetter() {
      this.updateRevision(this.revisions.length - 1, false, false);

      const payload = {
        recipient: this.chosenCompanion._id,
        title: this.currentSoulLetter.title
      };

      this.loading = true;
      this.loadingError = false;
      soulLettersApi
        .updateSoulLetter(this.currentSoulLetter._id, payload)
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          this.openSendSoulLetterModal();
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
          addNotification(
            "error",
            this.$t("misc.error"),
            this.$t("soulLetter.error.somethingWentWrong")
          );
        });
    },
    submitSoulLetter() {
      this.loading = true;
      this.loadingError = false;
      soulLettersApi
        .submitSoulLetter(this.currentSoulLetter._id)
        .then(res => {
          const soulLetter = res.data.data;
          this.loading = false;
          this.loadingError = false;
          addNotification(
            "success",
            this.$t("misc.success"),
            this.$t("soulLetter.success")
          );
          this.lineList = [];
          clearInterval(this.timerForRevisionUpdateIntervalCron);
          this.$emit("soulLetterSavedAndSent");
          if (
            soulLetter.latestRevision &&
            soulLetter.latestRevision.transaction &&
            soulLetter.latestRevision.transaction.buyUrl
          ) {
            window.location.replace(
              soulLetter.latestRevision.transaction.buyUrl.url
            );
          }
          //  this.setSoulLetterOnly(false);
        })
        .catch(err => {
          addNotification(
            "error",
            this.$t("misc.error"),
            this.$t("soulLetter.error.somethingWentWrong")
          );
          this.loading = false;
          this.loadingError = true;
        });
    },
    getAllRevisions(isOpenSoulLetterModal, isFirstMount) {
      this.loading = true;
      this.loadingError = false;
      revisionsApi
        .getAllRevisions(this.currentSoulLetter._id)
        .then(res => {
          const revisions = res.data.data;
          this.revisions = revisions;
          this.setRevisionVariables();
          this.constructLineList(isFirstMount);
          this.countCharacters();
          this.$emit("userAllowedToEditSoulLetter");
          this.loading = false;
          this.loadingError = false;
          if (isOpenSoulLetterModal) {
            this.$refs["sendSoulLetterModal"].show();
          }
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
          this.$emit("userNotAllowedToEditSoulLetter");
        });
    },
    setChosenClient() {
      this.chosenClient = {
        _id: this.currentSoulLetter.sender._id,
        givenName: this.currentSoulLetter.sender.profile.givenName,
        familyName: this.currentSoulLetter.sender.profile.familyName,
        name: this.currentSoulLetter.sender.profile.name,
        profileImage: this.currentSoulLetter.sender.profile.picture,
        role: this.currentSoulLetter.sender.role
      };
    },
    setQuillUndoRedoIcons() {
      const icons = Quill.import("ui/icons");
      icons["undo"] = `<svg viewbox="0 0 18 18">
        <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
        <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
      </svg>`;
      icons["redo"] = `<svg viewbox="0 0 18 18">
        <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
        <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
      </svg>`;
    }
  },
  created() {
    this.$nextTick(() => {
      this.quillDisabled = false;
    });
    this.setQuillUndoRedoIcons();
    if (this.getCurrentUser) {
      this.loading = true;
      this.currentUser = this.getCurrentUser;
      this.loading = false;
    }
    if (this.currentSoulLetter) {
      this.setChosenClient();
      this.soulLetterComment = this.currentSoulLetter.comment;
    }
    this.currentRevision = this.currentSoulLetter.latestRevision;
    this.getAllRevisions(false, true);
    const intervalCron = 30000; // 30 sec
    this.timerForRevisionUpdateIntervalCron = setInterval(() => {
      this.updateRevision(this.revisions.length - 1, false, true);
    }, intervalCron);
  },
  beforeDestroy() {
    clearInterval(this.timerForRevisionUpdateIntervalCron);
  }
};
</script>

<style scoped></style>
